.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
  transition: all 0.4s ease 0s ;

}

.header {
  background: #cefaf8;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

}

.header h2 {
  margin: 0;
  color: black;
}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .modal {
    right: calc(35% - 20rem);
    width: 20rem;
  }
}