.subcard {
  background: rgb(133, 200, 238);
  color: black;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  margin: 2rem auto;
  padding: 0.5rem;
  width: 90%;
  max-width: 15rem;
  height: auto;
  text-align: center;
}

/* .card ul{
  display: grid;
} */
.icon {
  justify-content: left;
  display: flex;
}
.subcard-title {
  display: grid;
  text-align: center;
}