.about h2 {
  text-align: center;
}

.contact {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgb(46, 45, 45) 100%), url('../../assets/contact.jpg');
  background-position: center;
  /* opacity: 0.7; */
}

.icons {
  font-size: 4.5em;
  margin: 15px;
  padding: 10px;
  opacity: 0.93;
  border: 1.7px solid rgba(200, 137, 230, 0.637);
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  display: grid;
  box-shadow: 1px 1px 1px 1px rgba(89, 4, 168, 0.137);
  overflow: hidden;
  transition: all 0.4s ease 0s;
}

.icons ul {
  display: grid;
}

.icons a {
  color: rgb(147, 132, 233)
}

@media (max-width: 767px) {
  .icons {
    margin: 10px;
  }
}

.icons:hover {
  transform: scale(1.05);
  overflow: hidden;
}

.icon-images {
  padding: 20px;
  line-height: 1.6;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  justify-content: center;
  padding-bottom: 15px;
}

.col {
  padding: 1rem;
  box-sizing: border-box;
  flex-grow: 1;
}

.col-6 {
  width: 50%;
}

.col-4 {
  width: 33.33%;
}