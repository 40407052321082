.App {
  text-align: center;
  background: #282c34;
  padding-bottom: 4rem;
    /* background: linear-gradient(#1f1f1f, #5a5959) ; */
  color: rgb(132, 233, 132)
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}

.App-link {
  color: #61dafb;
}


.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

@media screen and (max-width: 960px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
   .page {
    position: absolute;
    left: 15px;
    right: 15px;
  }
  
  .page-enter {
    opacity: 0;
  }
  
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 700ms, transform 700ms;
  }

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}