* {
  box-sizing: border-box;
  /* background: linear-gradient(#1f1f1f, #5a5959) ; */

}

html {
  /* font-family: 'Abril Fatface', cursive; */
  /* font-family: 'DM Serif Display', serif; */
  font-family: 'Patua One', cursive;
  /* background: #1f1f1f; */
  color: rgb(119, 140, 182);
  /* color: rgb(167, 185, 159); */

  
}

body {
  margin: 0;
  background: linear-gradient(to right, #000000, #090e16) ;
  /* background: linear-gradient(#1f1f1f, #5a5959) ; */

}