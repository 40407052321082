.main-image img {
  width: 30%;
  float: right;
  margin-top: 5rem;
  margin-right: 5rem;
  height: auto;
  /* padding-bottom: 4rem; */
  border-radius: 15px;
}

.home {
  width: 100%;
  padding: 50px;
  margin-top: 2rem;
  border: solid #bee0f3;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-width: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}


