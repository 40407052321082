.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 999;
}

.modal--open {
  opacity: 1;
  visibility: visible;
}

.modal__content {
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.modal__close:hover {
  color: red;
}
