.about-section {
  position: relative ;
  padding-top: 150px ;
  padding-bottom: 30px ;
  background-image: var(--section-background-color) ;
  color: white ;
}

.about h2{
  text-align: center;
}



.quote-card-view {
  border: none ;
  color: white ;
  background-color: transparent ;
}

.about-activity {
  list-style: none ;
  text-align: left ;
  padding-left: 1px ;
}